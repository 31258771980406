import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const Breadcrumbs = ({ title, isDark, parent, typeWide }) => {
  const { t } = useLocale()

  return (
    <section
      className={`breadcrumbs${isDark ? " breadcrumbs--dark" : ""}${
        typeWide ? " breadcrumbs--wider" : ""
      }`}
    >
      <div className="container-fluid">
        <motion.div className="breadcrumbs__wrapper" {...fadeAnimation}>
          <Link to={t("/")}>{t("Strona główna")}</Link>
          {parent && (
            <>
              <span>-</span>
              <Link to={t(parent?.slug)}>{t(parent?.title)}</Link>
            </>
          )}
          <span>-</span>
          <span>{t(title)}</span>
        </motion.div>
      </div>
    </section>
  )
}

export default Breadcrumbs
