import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import Button from "components/Button"

import { useLocale } from "context/locale"

import fadeAnimation from "utils/fadeAnimation"

const BanerContact = ({ withMargin }) => {
  const { t } = useLocale()

  return (
    <section
      className={`baner-contact${
        withMargin ? " baner-contact--with-margin" : ""
      }`}
    >
      <div className="container-fluid">
        <SectionHeader
          title={t("Masz wątpliwości,")}
          content={t("bądź dodatkowe pytania?")}
        />
        <motion.div className="baner-contact__cta" {...fadeAnimation}>
          <Button to={t("/kontakt/")}>{t("Kontakt")}</Button>
        </motion.div>
      </div>
    </section>
  )
}

export default BanerContact
